import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const hasPermission = (requiredPermission) => {
  const authData = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
  const permissions = authData?.data?.user_permissions || [];

  return permissions.includes(requiredPermission);
};

const hasAnyPermission = (...requiredPermissions) => {
  const authData = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
  const permissions = authData?.data?.user_permissions || [];

  return requiredPermissions.some(permission => permissions.includes(permission));
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  //Request interceptor
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  );

  //Response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {

      // Check if the status code is 403
      if (error.response && error.response.status === 401) {
        removeAuth();
      }

      return Promise.reject(error);
    }
  );
}

export {getAuth, setAuth, removeAuth, hasPermission, hasAnyPermission, AUTH_LOCAL_STORAGE_KEY}
