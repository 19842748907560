import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useInstitution} from '../../../../../app/modules/auth/core/InstitutionContext'
import {getAuth, hasPermission, hasAnyPermission} from '../../../../../app/modules/auth/core/AuthHelpers'

export function MenuInner() {
  const intl = useIntl()
  const {institutions, selectedInstitution, setSelectedInstitution, setInstitutions} =
    useInstitution()

  const auth = getAuth()
  const userRoles = auth?.data?.user_roles

  const isAdmin = userRoles?.some((role) => role === 'admin')
  const anyPeiPlanning = hasAnyPermission('strategicDirection-read','objectiveProgram-read','linkedProgram-read','countryVision-read','LinkageSustainableDevelopmentGoal-write','LinkageNationalDevelopmentPlan-write', 'TransversalAxis-write', 'LinkageSustainableDevelopmentGoal-read','LinkageNationalDevelopmentPlan-read', 'TransversalAxis-read');
  const anyPeiMonitoring = hasAnyPermission('linkageSummary-read','strategicIndicator-read','finalProductIndicator-read','performanceIndicators-read');
  const anyPoaPlanning = hasAnyPermission('operationalAnnualPlan-read','operationalAnnualPlan-write');
  const anyPoaMonitoring = hasAnyPermission('monitoringOperationalAnnualPlan-read','monitoringOperationalAnnualPlan-write','POAExec-read');
  const anyReports = hasAnyPermission('reports-sinit-pei-read', 'reports-sinit-poa-read', 'publicPolicies-report', 'reports-monitoring');
  const anySinitReports = hasAnyPermission('reports-sinit-pei-read', 'reports-sinit-poa-read');
  const anyDocCertificates = hasAnyPermission('programAlignnment-read','certificatePDM-read');
  const anyDocOpinions = hasAnyPermission('technicalOpinionPEI-read','technicalOpinionUpdatePEI-read','technicalOpinionPoa-read','technicalOpinionModificationPoa-read','poaUpdateAdemdum-read','technicalOpinion-read','opinionPDM-read');
  const anyAdminConfig = hasAnyPermission('planning-periods-read','institutions-read','departments-read','municipalities-read','signers-read','unit-measurements-read','municipal-development-categories-read','currency-read','financial-read','financialEntity-read','cabinets-read','policyTypes-read','policyState-read');
  const anyAdminCatalog = hasAnyPermission('transverseAxles-read','pegAdmin-read','countryVisionAdmin-read','odsAdmin-read');
  const anyAdminSecurity = hasAnyPermission('users-read','role-read','permission-read');

  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuItem fontIcon='bi-house-door' title='Home' to='/dashboard' />

      {(anyPeiPlanning || anyPeiMonitoring) && (
        <MenuInnerWithSub
          title='Planificación Estratégica Institucional'
          to='#'
          fontIcon='bi-puzzle-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {anyPeiPlanning && <MenuInnerWithSub
            title='Planificación'
            to='#'
            fontIcon='bi-calendar-event'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('strategicDirection-read') && (
              <MenuItem
                fontIcon='bi-compass-fill'
                to='pei/planificacion/direccionamiento-estrategico'
                title='Direccionamiento Estratégico'
              />
            )}
            {hasPermission('objectiveProgram-read') && (
              <MenuItem
                fontIcon='bi-bullseye'
                to='pei/planificacion/objetivos-programa'
                title='Objetivos Estratégicos del Programa'
              />
            )}
            {hasPermission('linkedProgram-read') && (
              <MenuItem
                fontIcon='bi-link'
                to='/pei/planificacion/direccionamiento-estrategico/programas-vinculados'
                title='Programas vinculados'
              />
            )}
            {hasPermission('countryVision-read') && (
              <MenuItem
                fontIcon='bi-flag-fill'
                to='/pei/planificacion/direccionamiento-estrategico/vision-pais'
                title='Visión País'
              />
            )}
            {(hasPermission('LinkageSustainableDevelopmentGoal-write') || hasPermission('LinkageSustainableDevelopmentGoal-read')) && (
              <MenuItem
                fontIcon='bi-recycle'
                to='/pei/planificacion/direccionamiento-estrategico/objetivos-desarrollo-sostenible'
                title='Agenda Nacional 2030 de los ODS'
              />
            )}
            {(hasPermission('LinkageNationalDevelopmentPlan-write') || hasPermission('LinkageNationalDevelopmentPlan-read')) && (
              <MenuItem
                fontIcon='bi-building'
                to='/pei/planificacion/direccionamiento-estrategico/vinculacion-plan-estrategico-gobierno'
                title='Plan Estratégico de Gobierno'
              />
            )}
            {(hasPermission('TransversalAxis-write')||hasPermission('TransversalAxis-read')) && (
              <MenuItem
                fontIcon='bi-arrow-down-up'
                to='/pei/planificacion/direccionamiento-estrategico/ejes-transversales'
                title='Ejes Transversales'
              />
            )}
          </MenuInnerWithSub>}

          {anyPeiMonitoring && <MenuInnerWithSub
            title='Seguimiento'
            to='#'
            fontIcon='bi-card-checklist'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('linkageSummary-read') && <MenuItem
              fontIcon='bi-table'
              to='pei/seguimiento/resumen-vinculacion'
              title='Resumen de Vinculación'
            />}
            {hasPermission('strategicIndicator-read') && <MenuItem
              fontIcon='bi-list'
              to='pei/seguimiento/indicadores-estrategicos'
              title='Indicadores Estratégicos'
            />}
            {hasPermission('finalProductIndicator-read') && (
              <MenuItem
                fontIcon='bi-archive-fill'
                to='pei/seguimiento/productos-finales'
                title='Productos Finales e Indicadores'
              />
            )}
            {hasPermission('performanceIndicators-read') && (
              <MenuItem
                fontIcon='bi-bar-chart-fill'
                to='pei/seguimiento/indicadores-desempenio'
                title='Indicadores de Desempeño'
              />
            )}
          </MenuInnerWithSub>}
        </MenuInnerWithSub>
      )}

      {(anyPoaPlanning || anyPoaMonitoring) && (
        <MenuInnerWithSub
          title='Plan Operativo Anual'
          to='#'
          fontIcon='bi-calendar-check-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {anyPoaPlanning && <MenuInnerWithSub
            title='Planificación'
            to='#'
            fontIcon='bi-calendar-event'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('operationalAnnualPlan-read') && (
              <MenuItem
                fontIcon='bi-pencil-square'
                to='pao/planificacion/plan-anual-operativo'
                title='Ingreso'
              />
            )}
            {hasPermission('operationalAnnualPlan-write') && (
              <MenuItem
                fontIcon='bi-check2-square'
                to='pao/planificacion/plan-anual-operativo-verificacion'
                title='Verificación'
              />
            )}
          </MenuInnerWithSub>}

          {anyPoaMonitoring && <MenuInnerWithSub
            title='Seguimiento'
            to='#'
            fontIcon='bi-card-checklist'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('monitoringOperationalAnnualPlan-read') && (
              <MenuItem
                fontIcon='bi-pencil-square'
                to='pao/planificacion/plan-anual-operativo-seguimiento'
                title='Ingreso'
              />
            )}
            {hasPermission('monitoringOperationalAnnualPlan-write') && (
              <MenuItem
                fontIcon='bi-check2-square'
                to='pao/planificacion/plan-anual-operativo-seguimiento-verificacion'
                title='Verificación'
              />
            )}
            {hasPermission('POAExec-read') && <MenuItem
              fontIcon='bi-check2-square'
              to='pao/planificacion/plan-anual-operativo-seguimiento-ejecucion'
              title='Ejecución'
            />}
          </MenuInnerWithSub>}
        </MenuInnerWithSub>
      )}

      {hasPermission('municipalDevelopmentPlanVerification-read') && (
        <MenuItem
          fontIcon='bi-map-fill'
          to='plan-desarrollo-municipal/verificacion'
          title='Plan de Desarrollo Municipal'
        />
      )}

      {anyReports && (
        <MenuInnerWithSub
          title='Reportes'
          to='/reports'
          fontIcon='bi-file-earmark-post-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {anySinitReports && (
            <MenuInnerWithSub
              title='SINIT'
              to='#'
              fontIcon='bi-file-earmark-post'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {hasPermission('reports-sinit-pei-read') && (
                <MenuItem
                  fontIcon='bi-calendar-event'
                  to='reportes/sinit/pei'
                  title='Seguimiento PEI'
                />
              )}
              {hasPermission('reports-sinit-poa-read') && (
                <MenuItem
                  fontIcon='bi-calendar-event'
                  to='reportes/sinit/poa'
                  title='Seguimiento POA'
                />
              )}
            </MenuInnerWithSub>
          )}
          {hasPermission('publicPolicies-report') && (
            <MenuItem
              fontIcon='bi-table'
              to='politicas-publicas/report'
              title='Políticas Públicas'
            />
          )}

          {hasPermission('reports-monitoring') && (
            <MenuItem
              fontIcon='bi-box-arrow-up-right'
              to='reportes/sinit/monitoreo'
              title='Reportes de Monitoreo'
            />
          )}
        </MenuInnerWithSub>
      )}

      {hasPermission('publicPolicies-read') && (
        <MenuItem
          fontIcon='bi-house-door'
          title='Políticas Públicas'
          to='politicas-publicas/index'
        />
      )}

      {(isAdmin || anyDocCertificates || anyDocOpinions) && (
        <MenuInnerWithSub
          title='Documentación'
          to='#'
          fontIcon='bi-patch-check-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {(isAdmin || anyDocCertificates) && (
            <MenuInnerWithSub
              title='Certificados'
              to='#'
              fontIcon='bi-gear-fill'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {(isAdmin || hasPermission('programAlignnment-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/alineamiento-programas'
                  title='Alineamiento de Programas'
                />
              )}

              {(isAdmin || hasPermission('certificatePDM-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/certificado-desarrollo-municipal'
                  title='Certificado de Plan de Desarrollo Municipal'
                />
              )}
            </MenuInnerWithSub>
          )}

          {(isAdmin || anyDocOpinions) && (
            <MenuInnerWithSub
              title='Dictamenes'
              to='#'
              fontIcon='bi-patch-check-fill'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {(isAdmin || hasPermission('technicalOpinionPEI-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/dictamen-legal'
                  title='Dictamen Técnico PEI'
                />
              )}
              {(isAdmin || hasPermission('technicalOpinionUpdatePEI-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/dictamen-tecnico-actualizacion-pei'
                  title='Dictamen Técnico de Actualización PEI'
                />
              )}
              {(isAdmin || hasPermission('technicalOpinionPoa-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/dictamen-tecnico'
                  title='Dictamen Técnico POA'
                />
              )}
              {(isAdmin || hasPermission('technicalOpinionModificationPoa-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/dictamen-tecnico-modificacion'
                  title='Dictamen Técnico de Modificación POA'
                />
              )}
              {(isAdmin || hasPermission('poaUpdateAdemdum-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/dictamen-tecnico-poa'
                  title='Ademdum de Modificación POA'
                />
              )}
              {(isAdmin || hasPermission('technicalOpinion-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/dictamen-opinion-tecnica'
                  title='Opinión Técnica'
                />
              )}
              {(isAdmin || hasPermission('opinionPDM-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/plan-maestro-desarrollo-municipal'
                  title='Dictamen de Plan de Desarrollo Municipal'
                />
              )}
              {/* {(isAdmin || hasPermission('certificatesMenu-read')) && (
                <MenuItem
                  fontIcon='bi-file-earmark-arrow-down-fill'
                  to='certificados/maestro-desarrollo-municipal'
                  // title='Desarrollo Municipal 01'
                  title='Dictamen de Plan de Desarrollo Municipal'
                />
              )} */}
            </MenuInnerWithSub>
          )}
        </MenuInnerWithSub>
      )}

      {(isAdmin || anyAdminConfig || anyAdminCatalog || anyAdminSecurity) && (
        <MenuInnerWithSub
          title='Administración'
          to='/administration'
          fontIcon='bi-gear-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {(isAdmin || anyAdminConfig) && (
            <MenuInnerWithSub
              title='Configuración'
              to='/indicadores'
              fontIcon='bi-gear-fill'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {(isAdmin || hasPermission('planning-periods-read')) && (
                <MenuItem
                  fontIcon='bi-calendar-event-fill'
                  to='administration/indicadores/periodos-planificacion'
                  title='Periodos de planificacion'
                />
              )}
              {(isAdmin || hasPermission('institutions-read')) && (
                <MenuItem
                  fontIcon='bi-building'
                  to='administration/indicadores/instituciones'
                  title='Instituciones'
                />
              )}
              {(isAdmin || hasPermission('departments-read')) && (
                <MenuItem
                  fontIcon='bi-map'
                  to='administration/indicadores/departamentos'
                  title='Departamentos'
                />
              )}
              {(isAdmin || hasPermission('municipalities-read')) && (
                <MenuItem
                  fontIcon='bi-map'
                  to='administration/indicadores/municipios'
                  title='Municipios'
                />
              )}

              {(isAdmin || hasPermission('signers-read')) && (
                <MenuItem
                  fontIcon='bi-pen'
                  to='administration/indicadores/firmantes'
                  title='Firmantes'
                />
              )}
              {(isAdmin || hasPermission('unit-measurements-read')) && (
                <MenuItem
                  fontIcon='bi-rulers'
                  to='administration/indicadores/unidades-medida'
                  title='Unidades de medida'
                />
              )}
              {(isAdmin || hasPermission('municipal-development-categories-read')) && (
                <MenuItem
                  fontIcon='bi-list'
                  to='administration/indicadores/desarrollo-municipal-categorias'
                  title='Categorías de Desarrollo Municipal'
                />
              )}
              {(isAdmin || hasPermission('currency-read')) && (
                <MenuItem
                  fontIcon='bi-currency-dollar'
                  to='administration/monedas'
                  title='Moneda'
                />
              )}
              {(isAdmin || hasPermission('fundingSource-read')) && (
                <MenuItem
                  fontIcon='bi-bank'
                  to='administration/fuentes-financiamiento'
                  title='Fuente de Financiamiento'
                />
              )}
              {(isAdmin || hasPermission('financialEntity-read')) && (
                <MenuItem
                  fontIcon='bi-briefcase'
                  to='administration/entidades-financiamiento'
                  title='Entidad de Fianciamiento'
                />
              )}
              {(isAdmin || hasPermission('cabinets-read')) && (
                <MenuItem fontIcon='bi-people' to='administration/cabinets' title='Gabinetes' />
              )}
              {(isAdmin || hasPermission('policyTypes-read')) && (
                <MenuItem
                  fontIcon='bi-list-ul'
                  to='administration/public-policy-type'
                  title='Tipos de politica publica'
                />
              )}
              {(isAdmin || hasPermission('policyState-read')) && (
                <MenuItem
                  fontIcon='bi-list-ul'
                  to='administration/public-policy-state'
                  title='Estados de politica publica'
                />
              )}
            </MenuInnerWithSub>
          )}

          {(isAdmin || anyAdminCatalog) && (
            <MenuInnerWithSub
              title='Catálogos'
              to='/planificacion'
              fontIcon='bi-list-ul'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {(isAdmin || hasPermission('transverseAxles-read')) && (
                <MenuItem
                  fontIcon='bi-arrow-down-up'
                  to='administration/planificacion/ejes-transversales'
                  title='Ejes Transversales'
                />
              )}
              {(isAdmin || hasPermission('pegAdmin-read')) && (
                <MenuItem
                  fontIcon='bi-building'
                  to='administration/planificacion/plan-estrategico-gobierno'
                  title='Plan Estratégico de Gobierno'
                />
              )}
                
              {(isAdmin || hasPermission('countryVisionAdmin-read')) && (
              <MenuItem
                fontIcon='bi-flag-fill'
                to='administration/planificacion/vision-pais'
                title='Vision Pais'
              />
              )}
              {(isAdmin || hasPermission('odsAdmin-read')) && (
              <MenuItem
                fontIcon='bi-flag-fill'
                to='administration/planificacion/objetivos-desarrollo-sostenible'
                title='Objetivos de Desarrollo Sostenible'
              />
              )}
            </MenuInnerWithSub>
          )}

          {(isAdmin || anyAdminSecurity) && (
            <MenuInnerWithSub
              title='Seguridad'
              to='/security'
              fontIcon='bi-shield-check'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {(isAdmin || hasPermission('users-read')) && <MenuItem
                fontIcon='bi-person-gear'
                to='administration/security/users'
                title='Usuarios'
              />}
              {(isAdmin || hasPermission('role-read')) && <MenuItem
                fontIcon='bi-bookmark-plus-fill'
                to='administration/security/roles'
                title='Roles'
              />}

              {(isAdmin || hasPermission('permission-read')) && <MenuItem
                fontIcon='bi-key'
                to='administration/security/permissions'
                title='Permisos'
              />}
            </MenuInnerWithSub>
          )}
        </MenuInnerWithSub>
      )}
    </>
  )
}
